const SalesOfficeTooltips = {
  activePapp: "Switch on for this office to receive leads and be active on fertighaus",
	msActivePapp: "Switch on for this office to receive leads and be active on MS sites",
	imActivePapp: "Switch on for this office to receive leads and be active on IM sites",
  name: "Sales office name",
  externalId: "",
  vendor: "The vendor the sales office belongs to",
  officeType: "",
  plan: "A sales office's branding plan: Only office's set to 'premium' are used to personalize house detail pages and can receive direct handover emails",
  disableRegionalBranding: "",
  handoverMethod: "Defines whether leads are handed over to the office in general or to individual sales agents. In case of the second option, the sales office display and handover regions are aggregated based on all sales agent individual regions and handover emails are personalized for the receiving sales agent",
  officeHandoverEmail: "If no handover email is specified on sales agent level, this email is used as a fallback for direct lead handover",
  company: "",
  website: "A sales office's website",
  addressLine1: "Street & house-number",
  postalCode: "",
  city: "",
  country: "",
  officePhone: "",
  officeProfilePicture: "Hero image displayed on the office detail on fertighaus",
  openingHoursMondayStart: "",
  openingHoursMondayEnd: "",
  lunchBreakMondayStart: "",
  lunchBreakMondayEnd: "",
  openingHoursMondayStatus: "",
  openingHoursTuesdayStart: "",
  openingHoursTuesdayEnd: "",
  lunchBreakTuesdayStart: "",
  lunchBreakTuesdayEnd: "",
  openingHoursTuesdayStatus: "",
  openingHoursWednesdayStart: "",
  openingHoursWednesdayEnd: "",
  lunchBreakWednesdayStart: "",
  lunchBreakWednesdayEnd: "",
  openingHoursWednesdayStatus: "",
  openingHoursThursdayStart: "",
  openingHoursThursdayEnd: "",
  lunchBreakThursdayStart: "",
  lunchBreakThursdayEnd: "",
  openingHoursThursdayStatus: "",
  openingHoursFridayStart: "",
  openingHoursFridayEnd: "",
  lunchBreakFridayStart: "",
  lunchBreakFridayEnd: "",
  openingHoursFridayStatus: "",
  openingHoursSaturdayStart: "",
  openingHoursSaturdayEnd: "",
  lunchBreakSaturdayStart: "",
  lunchBreakSaturdayEnd: "",
  openingHoursSaturdayStatus: "",
  openingHoursSundayStart: "",
  openingHoursSundayEnd: "",
  lunchBreakSundayStart: "",
  lunchBreakSundayEnd: "",
  openingHoursSundayStatus: "",
  services: "Possibility to specify services provided by the sales office",
  description: "General text used to describe the individual sales office",
  reportingName: "",
  reportingActiveSince: "",
  reportingEmail: "",
  reportingActive: "",
  handoverRegions: "ZIP codes in which sales office offers its services",
  imHandoverRegions: "ZIP codes in which office offers its services",
  displayRegions: "ZIP codes for which sales office is shown as an option on fertighaus",
	imDisplayRegions: "ZIP codes for which office is shown as an option on the IM sites",
  leadFeedbackReportRecipients: "Recipients of the monthly lead feedback report for this sales office",
  accountOwner: "Neuraum employee who is displayed in reports to the sales office",
  headOfSalesGender: "Gender of the Head of Sales - used for daily reporting",
  headOfSalesFirstName: "First name of the Head of Sales - used for daily reporting",
  headOfSalesLastName: "Last name of the Head of Sales - used for daily reporting",
  headOfSalesEmail: "Email of the Head of Sales - used for daily reporting",
	msSendHtmlInstantHandover: "Should the office receive every new MS lead instantly",
	msHtmlInstantHandoverTemplate: "Which reporting template should be used for immediate MS lead handover",
	msHtmlInstantHandoverEmails: "Who should receive the immediate MS lead handover email",
	msHtmlInstantHandoverEmailSubject: "The subject of the immediate MS lead handover email",
	msSendCsvHandover: "Should the office receive a CSV file email reporting",
	msCsvHandoverSchedule: "The schedule of the CSV file email reporting",
	msCsvHandoverFormat: "The format of the CSV file email reporting",
	msCsvHandoverEmails: "Who should receive the CSV file email reporting",
	msCsvHandoverEmailSubject: "The subject of the CSV file email reporting",
	msCsvHandoverTemplate: "The template of the CSV file email reporting",
	imRegion: "Text explaining in which region the office is active",
	imListingsUrl: "URL of their listings page on Immowelt",
	imIndexed: "Whether the office detail page should be indexed",
}

export default SalesOfficeTooltips;