<template>
  <CRow>
    <CCol class="weekday" sm="2">
      {{this.openingHours.weekday}}
    </CCol>
    <CCol sm="2">
      <multiselect
          :disabled="!allowEdit"
          v-model="openingHours.openingHoursStatus"
          @input="updateItem"
          :options="['open', 'by_appointment', 'closed']"
          :searchable="true"
          :close-on-select="true"
          :allow-empty="false"
          deselect-label="Required field"
      />
    </CCol>
    <CCol sm="2">
      <CInput
          v-model="openingHours.openingHoursStart"
          v-on:change="updateItem"
          type="time"
          description="Open from"
          :readonly="!(openingHours.openingHoursStatus==='open' && allowEdit)"
      />
    </CCol>
    <CCol sm="2">
      <CInput
          v-model="openingHours.openingHoursEnd"
          v-on:change="updateItem"
          type="time"
          description="Open to"
          :readonly="!(openingHours.openingHoursStatus==='open' && allowEdit)"
      />
    </CCol>
    <CCol sm="2">
      <CInput
          v-model="openingHours.lunchBreakStart"
          v-on:change="updateItem"
          type="time"
          description="Lunch break from"
          :readonly="!(openingHours.openingHoursStatus==='open' && allowEdit)"
      />
    </CCol>
    <CCol sm="2">
      <CInput
          v-model="openingHours.lunchBreakEnd"
          v-on:change="updateItem"
          type="time"
          description="Lunch break to"
          :readonly="!(openingHours.openingHoursStatus==='open' && allowEdit)"
      />
    </CCol>
  </CRow>
</template>

<script>
const clonedeep = require('lodash.clonedeep');
export default {
  name: "OpeningHoursForm",
  props: ["openingHoursIn"],
  methods: {
    updateItem(){
      const data = clonedeep(this.openingHours);
      this.$emit('update-item', data);
    }
  },
  computed: {
    openingHours(){
      return this.openingHoursIn? this.openingHoursIn: {}
    },
    allowEdit: {
      get() {
        return this.$store.state.allowEdit
      },
      set() {
      }
    }
  }
}
</script>

<style scoped>
 .multiselect {
   border-width: 2pt;
   ;
   list-style-type: none;
   margin-bottom: 1pt;
 }
 .weekday{
   text-align: right;
   padding-top: 0.5rem;
   font-weight: bold;
   justify-items: right;
 }
</style>